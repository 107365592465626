// src/components/onboarding/imagens/Step3.jsx

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './Step3.css';

const ImagesStep3 = ({ onNext }) => {
  const [position, setPosition] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Novo estado para controle de visibilidade
  const balloonRef = useRef(null);

  useEffect(() => {
    const adjustPosition = () => {
      const communityButton = document.querySelector('.community-button');
      if (communityButton && balloonRef.current) {
        const rect = communityButton.getBoundingClientRect();
        const balloon = balloonRef.current;
        const balloonWidth = balloon.offsetWidth;
        const balloonHeight = balloon.offsetHeight;

        // Posiciona o balão no lado direito do botão
        let top = rect.top + window.scrollY + rect.height / 2 - balloonHeight / 2;
        let left = rect.right + window.scrollX + 20;

        setPosition({ top, left });
      } else {
        setTimeout(adjustPosition, 100);
      }
    };

    // Chama a função imediatamente
    adjustPosition();

    // Adiciona a classe 'show' para animação de entrada
    setTimeout(() => {
      setIsVisible(true);
    }, 100);

    // Atualiza a posição em caso de redimensionamento da janela
    window.addEventListener('resize', adjustPosition);

    return () => {
      window.removeEventListener('resize', adjustPosition);
    };
  }, []);

  const handleNext = () => {
    setIsVisible(false); // Remove a classe para animação de saída
    setTimeout(() => {
      onNext(); // Chama a próxima etapa após a animação
    }, 500);
  };

  return (
    <div
      className={`images-onboarding-step3 ${isVisible ? 'show' : ''}`}
      style={{
        top: position ? position.top : -9999,
        left: position ? position.left : -9999,
      }}
    >
      <div className="images-step3-balloon" ref={balloonRef}>
        <span className="step-number">3</span>
        <p>
          Confira aqui as imagens geradas pela comunidade do CMOs.ai. Inspire-se. Copie. Use e abuse.
        </p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Entendi</button>
        </div>
      </div>
    </div>
  );
};

ImagesStep3.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default ImagesStep3;
