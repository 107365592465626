import React, {createContext, useState, useEffect} from 'react';
import {PermissionGroups as PermssionGroups} from "../../services/facade/PermissionGroups";
import {usePersistedState} from "../hooks/usePersistedState";

export const PermissionGroupsContext = createContext([]);

export const PermissionGroupsProvider = ({children}) => {
    const [permissionGroups, setPermissionGroups] = usePersistedState("permissionGroups", []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        PermssionGroups.getUserGroups().then(groups => {
            setPermissionGroups(groups);
            setIsLoading(false);
        });
    }, [children, setPermissionGroups]);

    const refetchPermissionGroups = () => {
        setIsLoading(true);
        return PermssionGroups.getUserGroups().then(groups => {
            setPermissionGroups(groups);
            setIsLoading(false);
        });
    }

    return (
        <PermissionGroupsContext.Provider value={[permissionGroups, isLoading, refetchPermissionGroups]}>
            {children}
        </PermissionGroupsContext.Provider>
    );
};
