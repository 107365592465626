import React, { useState } from 'react';
import { List } from "antd";
import BrandVoiceUpdateModal from "./BrandVoiceUpdateModal";
import BrandVoiceListItem from "./BrandVoiceListItem";

const BrandVoiceList = ({ brandVoices, isLoading, filter }) => {
    const [openCreateVoiceModal, setOpenCreateVoiceModal] = useState(false);

    const getFilteredBrandVoices = () => {
        if (!filter) {
            return brandVoices;
        }

        return brandVoices.filter((bv) => {
            return bv.name.toLowerCase().includes(filter.toLowerCase())
                || bv.description.toLowerCase().includes(filter.toLowerCase());
        });
    }

    const renderHeader = () => {
        return (
            <div className="px-4 font-sans">
                <div className="text-lg">
                    Suas vozes
                </div>
            </div>
        );
    }

    const renderItem = (item) => {
        return (
            <BrandVoiceListItem item={item} />
        );
    }

    const filteredBrandVoices = getFilteredBrandVoices();

    return (
        <div className="my-4">
            <List
                loading={isLoading}
                itemLayout="horizontal"
                header={renderHeader()}
                dataSource={filteredBrandVoices}
                size="large"
                renderItem={renderItem}
                bordered
                pagination={{
                    pageSize: 5
                }}
                locale={{
                    emptyText: (!isLoading && brandVoices && brandVoices.length === 0) ? (
                        <div className="mt-6 text-center text-gray-500">
                            <p>Você ainda não possui vozes de marca criadas.</p>
                            <p>Clique em <strong>"Adicionar Voz de Marca"</strong> para começar a criar vozes personalizadas que refletem o estilo único da sua marca.</p>
                        </div>
                    ) : (
                        <div className="mt-6 text-center text-gray-500">
                            Nenhuma voz de marca encontrada para o filtro aplicado.
                        </div>
                    )
                }}
            />

            <BrandVoiceUpdateModal
                open={openCreateVoiceModal}
                onClose={() => setOpenCreateVoiceModal(false)}
            />
        </div>
    );
};

export default BrandVoiceList;
