// src/context/GenerationModelClassContext.js

import React, { createContext, useState } from 'react';

export const GenerationModelClassContext = createContext();

export const GenerationModelClassProvider = ({ children }) => {
  const [generationModelClass, setGenerationModelClass] = useState('regular');

  return (
    <GenerationModelClassContext.Provider value={{ generationModelClass, setGenerationModelClass }}>
      {children}
    </GenerationModelClassContext.Provider>
  );
};
