// src/components/tools/ToolsContainer.jsx

import React, { Component } from "react";
import autoBind from "auto-bind";
import { Layout } from "antd";
import { ToolsRemote } from "../../services/dao/remote/tools";
import { useLocation, useNavigate } from "react-router-dom";
import ToolsFilter from "./ToolsFilter";
import ToolsCategory from "./ToolsCategory";
import "./ToolsContainer.css";
import _ from "lodash";
import MainHeaderTitle from "../main-header/MainHeaderTitle";
import { faWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingScreen from "../loading-screen/LoadingScreen";
import useUser from "../../utils/hooks/useUser";
import { useCurrentSubscriptionPlans } from "../../utils/hooks/useCurrentSubscriptionPlan";
import api from "../../services/api"; // Importar o serviço API
import Onboarding from "../onboarding/Onboarding"; // Importar o componente de Onboarding
import PropTypes from 'prop-types';

class ToolsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tools: [],
      isLoading: false,

      filteredTools: [],

      toolTextFilter: "",
      toolCategoriesFilter: [],

      pinnedTools: [],

      showOnboarding: false, // Estado para controlar a exibição do onboarding
    };
    autoBind(this);
  }

  componentDidMount() {
    const { setHeaderTitle, user } = this.props;
    const activeSubscriptions = _.get(user.user, "activeSubscriptions", []);

    document.title = "Agentes · CMOs.ai";
    this.setState({ isLoading: true });

    ToolsRemote.list(1).then((tools) => {
      this.setState({ tools: tools.filter(tool => tool.organizationIds.includes(1)), isLoading: false });
    });

    setHeaderTitle(
      <MainHeaderTitle
        icon={<FontAwesomeIcon icon={faWandSparkles} />}
        title="Agentes"
      />
    );

    // Verificar o status do onboarding
    this.fetchOnboardingStatus();
  }

  async fetchOnboardingStatus() {
    try {
      const response = await api.get('/api/user');
      const { hasSeenAgentsOnboarding } = response.data;
      if (!hasSeenAgentsOnboarding) {
        this.setState({ showOnboarding: true });
      }
    } catch (error) {
      console.error('Erro ao buscar status do onboarding dos Agentes:', error);
    }
  }

  handleOnboardingFinish() {
    this.setState({ showOnboarding: false });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !_.isEqual(this.state, nextState)
      || !_.isEqual(this.props, nextProps);
  }

  getFilteredTools() {
    const { tools, toolTextFilter, toolCategoriesFilter } = this.state;

    if (_.isEmpty(toolTextFilter) && _.isEmpty(toolCategoriesFilter)) {
      return tools;
    }

    return tools.filter((tool) => {
      return (
        (!_.isEmpty(toolTextFilter) && tool.name.toLowerCase().includes(toolTextFilter.toLowerCase())) ||
        (!_.isEmpty(toolTextFilter) && tool.description.toLowerCase().includes(toolTextFilter.toLowerCase())) ||
        (tool.category != null && toolCategoriesFilter.map(t => t.toLowerCase()).includes(tool.category.toLowerCase()))
      );
    });
  }

  getToolsTags() {
    const { tools } = this.state;

    if (_.isEmpty(tools)) {
      return [];
    }

    return _.uniq(
      tools.reduce((tags, tool) => {
        return [...tags, ..._.get(tool, "tags", [])];
      }, [])
    );
  }

  getCategories() {
    const { tools } = this.state;

    if (_.isEmpty(tools)) {
      return [];
    }

    return _.uniq(tools.map(tool => tool.category));
  }

  getCurrentCategoryKey() {
    const { location } = this.props;
    if (location?.state?.categoryKey) {
      return location.state.categoryKey;
    }
    return null;
  }

  getCategoriesOrder() {
    const categoryKey = this.getCurrentCategoryKey();

    switch (categoryKey) {
      case "content":
        return ["Conteúdo", "Redes sociais", "Produtividade", "Gestão", "Vendas"].reverse();
      case "social":
        return ["Redes sociais", "Conteúdo", "Produtividade", "Gestão", "Vendas"].reverse();
      case "productivity":
        return ["Produtividade", "Conteúdo", "Redes sociais", "Gestão", "Vendas"].reverse();
      case "management":
        return ["Gestão", "Produtividade", "Vendas", "Conteúdo", "Redes sociais"].reverse();
      case "sales":
        return ["Vendas", "Produtividade", "Gestão", "Conteúdo", "Redes sociais"].reverse();
      default:
        return ["Conteúdo", "Redes sociais", "Produtividade", "Gestão", "Vendas"].reverse();
    }
  }

  onClickToolCard(idTool) {
    const { navigate } = this.props;
    navigate(`/tools/${idTool}`);
  }

  handleToolTextFilterChange(text) {
    this.setState({ toolTextFilter: text });
  }

  handleToggleToolCategoriesFilter(category) {
    const { toolCategoriesFilter } = this.state;

    if (toolCategoriesFilter.includes(category)) {
      this.setState({ toolCategoriesFilter: toolCategoriesFilter.filter(toolCategory => toolCategory !== category) });
    } else {
      this.setState({ toolCategoriesFilter: [...toolCategoriesFilter, category] });
    }
  }

  handleClearFilters() {
    this.setState({ toolCategoriesFilter: [], toolTextFilter: "" });
  }

  handlePinTool(idTool) {
    const pinnedTools = _.cloneDeep(this.state.pinnedTools);
    if (pinnedTools.includes(idTool)) {
      pinnedTools.splice(pinnedTools.indexOf(idTool), 1);
    } else {
      pinnedTools.push(idTool);
    }
    this.setState({ pinnedTools });
  }

  renderTools() {
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    return this.renderToolsByCategory(this.getFilteredTools());
  }

  renderToolsByCategory(filteredTools) {
    const { pinnedTools } = this.state;
    const groupedTools = filteredTools.reduce((acc, tool) => {
      if (!acc[tool.category]) {
        acc[tool.category] = [];
      }

      acc[tool.category].push(tool);
      return acc;
    }, {});

    const preferedOrder = this.getCategoriesOrder();

    const list = Object.entries(groupedTools).map(([category, tools]) => {
      return (
        <ToolsCategory
          key={category}
          category={category}
          tools={tools}
          onClick={this.onClickToolCard}
          onPin={this.handlePinTool}
          pinnedTools={pinnedTools}
        />
      );
    });

    const sortedList = list.sort((a, b) => {
      return preferedOrder.indexOf(b.props.category) - preferedOrder.indexOf(a.props.category);
    });

    return sortedList;
  }

  render() {
    const { toolCategoriesFilter, isLoading, showOnboarding } = this.state;
    const { user } = this.props;

    return (
      <>
        {showOnboarding && (
          <Onboarding
            user={user}
            onFinish={() => this.handleOnboardingFinish()}
            onboardingType="agents"
          />
        )}
        <ToolsFilter
          categories={this.getCategories()}
          onTextFilter={this.handleToolTextFilterChange}
          onToggleCategoryFilter={this.handleToggleToolCategoriesFilter}
          onClearFilters={this.handleClearFilters}
          toolCategoriesFilter={toolCategoriesFilter}
          isLoading={isLoading}
        />
        <Layout className="ToolsContainer">
          {this.renderTools()}
        </Layout>
      </>
    );
  }
}

ToolsContainer.propTypes = {
  setHeaderTitle: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
};

const ToolsContainerWrapper = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const { currentSubscriptionPlans } = useCurrentSubscriptionPlans();

  return (
    <ToolsContainer
      location={location}
      navigate={navigate}
      user={user}
      currentSubscriptionPlans={currentSubscriptionPlans}
      {...props}
    />
  );
};

export default ToolsContainerWrapper;
