// src/services/api.js

import axios from 'axios';
import { Auth } from 'aws-amplify';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Interceptor para adicionar o token JWT nas requisições
api.interceptors.request.use(
  async (config) => {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.error('Usuário não autenticado:', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
