import React, { useEffect, useState } from 'react';
import MainHeaderTitle from "../../components/main-header/MainHeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faPlus } from "@fortawesome/free-solid-svg-icons";
import BrandVoiceList from "./components/BrandVoiceList";
import { Avatar, Input, Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import BrandVoiceCreateModal from "./components/BrandVoiceCreateModal";
import PremiumButton from "../../components/buttons/PremiumButton";
import { useSubscriptionService } from "../../utils/hooks/useSubscriptionService";
import { useBrandVoices } from "../../utils/hooks/useBrandVoices";
import _ from "lodash";
import UpgradeButton from "../../components/subscription/UpgradeButton";
import useIsMobile from "../../utils/hooks/useIsMobile";

const BrandVoicePage = ({ setHeaderTitle }) => {
    const [filter, setFilter] = useState('');
    const [openCreateVoiceModal, setOpenCreateVoiceModal] = useState(false);

    const subscriptionService = useSubscriptionService();
    const { brandVoices, isLoading } = useBrandVoices();
    const isMobile = useIsMobile();

    useEffect(() => {
        document.title = `Vozes de Marca · CMOs.ai`;
        setHeaderTitle(
            <MainHeaderTitle
                icon={<FontAwesomeIcon icon={faBullhorn} />}
                title="Vozes de Marca"
            />
        );
    }, [setHeaderTitle]);

    const canUserCreateMoreBrandVoices = () => {
        if (!brandVoices || isLoading) return false;

        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();
        const numberOfBrandVoices = _.get(activeSubscriptionPlan, "numberOfBrandVoices", 0);

        return brandVoices.length < numberOfBrandVoices;
    }

    const handleCreateBrandVoice = () => {
        if (canUserCreateMoreBrandVoices()) {
            setOpenCreateVoiceModal(true);
        }
    }

    const renderUpgradeWarningPopoverContent = () => {
        const activeSubscriptionPlan = subscriptionService.getUserActiveSubscriptionPlan();
        const numberOfBrandVoices = _.get(activeSubscriptionPlan, "numberOfBrandVoices", 0);

        return (
            <div className="font-primary w-[200px] flex flex-col gap-2">
                <div>Você atingiu o limite de {numberOfBrandVoices} vozes de marca no seu plano atual.</div>
                <div>Faça um upgrade para criar mais vozes de marca e expandir suas possibilidades.</div>
                <UpgradeButton size="large" />
            </div>
        )
    }

    const renderCreateButton = () => {
        const button = (
            <PremiumButton
                className="flex flex-row items-center gap-2"
                type="primary"
                size="large"
                onClick={handleCreateBrandVoice}
                disabled={!canUserCreateMoreBrandVoices()}
            >
                <FontAwesomeIcon icon={faPlus} />
                Adicionar Voz de Marca
            </PremiumButton>
        );

        if (!canUserCreateMoreBrandVoices()) {
            return (
                <Popover
                    content={renderUpgradeWarningPopoverContent()}
                    trigger="hover"
                    placement="right"
                >
                    {button}
                </Popover>
            );
        } else {
            return button;
        }
    }

    return (
        <div className="p-4 mx-8">
            <div className="flex flex-row gap-3 items-center">
                {!isMobile && <Avatar className="bg-premium" icon={<FontAwesomeIcon icon={faBullhorn} />} />}
                <div className="font-sans">
                    <div className="text-3xl">
                        Vozes de Marca
                    </div>

                    <div className="text-sm text-gray-400">
                        Crie e gerencie vozes de marca para personalizar o estilo e tom dos conteúdos gerados pelo CMOs.ai.
                    </div>
                </div>
            </div>

            <div className="py-4">
                <div className="flex flex-row gap-2 items-center">
                    <Input
                        size="large"
                        placeholder="Buscar vozes de marca por nome ou descrição"
                        prefix={<SearchOutlined />}
                        value={filter}
                        onChange={(e) => { setFilter(e.target.value) }}
                    />

                    {renderCreateButton()}
                </div>

                <BrandVoiceList
                    brandVoices={brandVoices}
                    isLoading={isLoading}
                    filter={filter}
                />

                {!canUserCreateMoreBrandVoices() && (
                    <div className="my-3 flex justify-center">
                        <UpgradeButton size="large" label="Fazer Upgrade" />
                    </div>
                )}
            </div>

            <BrandVoiceCreateModal
                open={openCreateVoiceModal}
                onClose={() => setOpenCreateVoiceModal(false)}
            />
        </div>
    );
};

export default BrandVoicePage;
