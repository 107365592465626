// src/components/chat/ChatRenderer.jsx

import React, { useEffect, useRef, useState, useCallback } from "react";
import "./ChatRenderer.css";
import TextInput from "../text-input/TextInput";
import Message from "../message/Message";
import CompletionRemote from "../../services/dao/remote/completion";
import {
  Drawer,
  Layout,
  Modal,
  Tooltip,
  notification,
  message,
  Dropdown,
  Avatar,
  Button,
  Spin,
  Tag,
} from "antd";
import { Specialties } from "../../api/specialties";
import _ from "lodash";
import { Content } from "antd/es/layout/layout";
import { QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { useGenerationModelClass } from "../../utils/hooks/useGenerationModelClass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PremiumButton from "../buttons/PremiumButton";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faUpload, faFile } from "@fortawesome/free-solid-svg-icons";
import SpecialtySelector from "./SpecialtySelector";
import Logo from "../../images/message.png";
import SuggestionBox from "./SuggestionBox";
import { useMutation, useQuery } from "@tanstack/react-query";
import { MessageRemote } from "../../services/dao/remote/message";
import BrandVoiceSelector from "./BrandVoiceSelector";
import GenerationModelClassSelector from "../../app/generation-model-class/GenerationModelClassSelector";
import CreditsCounter from "../../app/generation-model-class/CreditsCounter";
import { useWallet } from "../../utils/hooks/useWallet";
import NoMoreTextCreditsModal from "../../app/generation-model-class/NoMoreTextCreditsModal";
import PropTypes from "prop-types";
import ArtifactViewer from "../Artifact/ArtifactViewer";
import { useSpecialties } from "../../utils/hooks/useSpecialties";

const ChatRenderer = ({
  idChat,
  user,
  specialty = null,
  brandVoice = null,
  onSpecialtyChange,
  onBrandVoiceChange,
  onAIWriting,
  onMessageSent,
  onArtifactPreview,
}) => {
  const [isAIWritingState, setAIWriting] = useState(false);
  const [showSpecialtyFeatureDrawer, setShowSpecialtyFeatureDrawer] = useState(false);
  const [showSpecialtyModalMobile, setShowSpecialtyModalMobile] = useState(false);
  const [userPicture, setUserPicture] = useState(null);
  const [selectedCodeSnippet, setSelectedCodeSnippet] = useState(null);
  const isMobile = useIsMobile();

  let autoScroll = useRef(true);
  const chatBox = useRef();
  const textInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const textInputContainerRef = useRef(null);
  const [messageApi, messageContextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const messageDelayWarningTimeout = useRef(null);
  const { generationModelClass } = useGenerationModelClass();
  console.log('generationModelClass:', generationModelClass);
  const [messages, setMessages] = useState([]);
  const { refetch, textCredits, isLoading: isWalletLoading } = useWallet();
  const [hideNoMoreTextCreditsModal, setHideNoMoreTextCreditsModal] = useState(false);
  const { specialties, isLoading: isSpecialtiesLoading } = useSpecialties();

  // Estados e referências para o upload de arquivos
  const [uploadedFileUri, setUploadedFileUri] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadedFileType, setUploadedFileType] = useState(null);

  // Verificar se o modelo é Gemini
  const isGeminiModel = generationModelClass === 'gemini-flash' || generationModelClass === 'gemini-pro';

  // Constantes para as chaves das mensagens
  const MESSAGE_DELAY_WARNING_KEY = "message-delay-warning-chat";
  const WAIT_MESSAGE_KEY = "waitMessage";

  // Definir os tipos de arquivos permitidos
  const allowedFileTypes = [
    'pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'heic', 'heif',
    'mp4', 'mpeg', 'mov', 'avi', 'flv', 'mpg', 'webm', 'wmv', '3gpp',
    'wav', 'mp3', 'aiff', 'aac', 'ogg', 'flac'
  ];
  const allowedFileTypesString = allowedFileTypes.join(', ');

  useEffect(() => {
    console.log("idChat recebido pelo ChatRenderer:", idChat);
    handleScrollDown(true);
    const picture = getUserPicture();
    console.log("URL da imagem de perfil do usuário:", picture);
    setUserPicture(picture);
  }, [idChat, user]);

  const { mutateAsync: createMessageRemoteFn } = useMutation({
    mutationFn: (newMessage) => {
      console.log('Creating message:', newMessage);
      return MessageRemote.create(newMessage);
    },
  });

  const { isLoading, isFetched } = useQuery({
    queryKey: ['messages', idChat],
    queryFn: () => MessageRemote.list(idChat),
    refetchOnWindowFocus: false,
    onSuccess: (messages) => {
      console.log('Mensagens carregadas:', messages);

      // Converter timestamp para instância de Date
      const messagesWithDates = messages.map((msg) => ({
        ...msg,
        timestamp: new Date(msg.timestamp),
      }));

      setMessages(messagesWithDates);
      handleScrollDown(true);
    },
    onError: (error) => {
      console.error("Erro ao carregar mensagens:", error);
    }
  });

  const getUserPicture = () => {
    const defaultPicture = localStorage.getItem(`${user?.email}/defaultPicture`);
    if (user?.picture) {
      if (user.picture.startsWith("http")) {
        return user.picture;
      }
      return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${user.idUser}/256/${user.picture}`;
    } else if (defaultPicture) {
      return defaultPicture;
    }
    return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/256/default.png`;
  };

  const openMessageDelayWarning = (currentUploadedFileType) => {
    let contentMessage = "A resposta está demorando um pouco mais do que o esperado...";

    if (isGeminiModel && currentUploadedFileType && (currentUploadedFileType.startsWith('audio/') || currentUploadedFileType.startsWith('video/'))) {
      contentMessage = "Analisando arquivos, o processamento pode demorar um pouco. Por favor, aguarde...";
    }

    // Antes de abrir uma nova mensagem, destrua qualquer mensagem existente com as chaves usadas
    messageApi.destroy(WAIT_MESSAGE_KEY);
    messageApi.destroy(MESSAGE_DELAY_WARNING_KEY);

    messageApi.open({
      key: MESSAGE_DELAY_WARNING_KEY,
      type: "loading",
      content: contentMessage,
      duration: 0,
    });
  };

  const openRequestErrorNotification = () => {
    notificationApi["error"]({
      message: "Erro ao processar mensagem",
      description: (
        <>
          <div style={{ padding: "5px 0" }}>
            Estamos com um <b>alto nível de utilização</b> no momento, o que está ocasionando <b>lentidão no sistema</b>. Nosso time já está atuando para resolver o problema.
          </div>
          <div style={{ padding: "6px 0" }}>
            Pedimos desculpas pelo inconveniente e agradecemos a sua compreensão.
          </div>
        </>
      ),
      duration: 60,
    });
  };

  const startMessageDelayWarningTimeout = () => {
    const currentUploadedFileType = uploadedFileType;
    messageDelayWarningTimeout.current = setTimeout(() => {
      openMessageDelayWarning(currentUploadedFileType);
    }, 7500);
  };

  const stopMessageDelayWarningTimeout = () => {
    messageApi.destroy(MESSAGE_DELAY_WARNING_KEY);
    clearTimeout(messageDelayWarningTimeout.current);
  };

  const handleScrollDetector = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const atBottom = scrollTop + clientHeight >= scrollHeight;
    autoScroll.current = atBottom;
  };

  const handleScrollDown = (force = false) => {
    if (force) {
      autoScroll.current = true;
    }

    if (autoScroll.current && chatBox && chatBox.current) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
    }
  };

  const handleCreateUserMessage = async (message) => {
    try {
      setMessages((old) => [...old, message]);
      setTimeout(() => {
        handleScrollDown(true);
      }, 500);
      handleScrollDown(true);
      onMessageSent(idChat);
      await createMessageRemoteFn(message);
      await handleOpenAIMessage(message);
      setUploadedFileUri(null); // Resetar após o envio
      setUploadedFileName(null);
    } catch (error) {
      console.error("Erro em handleCreateUserMessage:", error);
    }
  };

  const handleCreateSystemMessage = (message) => {
    setMessages((old) => [...old, message]);
  };

  const handleUpdateOngoingSystemMessage = (message) => {
    setMessages((old) => [...old.slice(0, -1), message]);
  };

  const handleUpdateReadySystemMessage = (message) => {
    console.log('Saving message:', message);
    setMessages((old) => [...old.slice(0, -1), message]);
    createMessageRemoteFn(message).catch(error => {
      console.error("Erro ao salvar mensagem:", error);
    });
  };

  const displaySpecialtyFeatureDrawer = () => {
    setShowSpecialtyFeatureDrawer(true);
  };

  const hideSpecialtyFeatureDrawer = () => {
    setShowSpecialtyFeatureDrawer(false);
  };

  const getSpecialtyItems = () => {
    if (!specialties) return [];
    const sortedSpecialties = _.orderBy(specialties, ['name'], ['asc']);
    return sortedSpecialties.map((specialty) => {
      return {
        label: specialty.name,
        key: specialty.idSpecialty,
      };
    });
  };

  const handleSpecialtyChangeLocal = (specialty) => {
    onSpecialtyChange(idChat, specialty);
  };

  const handleBrandVoiceChangeLocal = (brandVoice) => {
    onBrandVoiceChange(idChat, brandVoice);
  };

  // Função para lidar com a mudança de arquivo
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  // Função para fazer upload do arquivo
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    setIsUploadingFile(true); // Iniciar o indicador de carregamento
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload`, {
        method: 'POST',
        body: formData,
        credentials: 'include', // Inclua se necessário
      });
  
      if (response.ok) {
        const data = await response.json();
        const { file_uri, mime_type } = data;
        setUploadedFileUri(file_uri);
        setUploadedFileName(file.name);
        setUploadedFileType(mime_type);
        message.success('Arquivo enviado com sucesso!');
      } else {
        message.error('Falha ao enviar o arquivo.');
      }
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
      message.error('Erro ao enviar o arquivo.');
    } finally {
      setIsUploadingFile(false); // Parar o indicador de carregamento
    }
  };

  const handleUserSendingMessage = async (content) => {
    try {
      const message = {
        idChat: idChat,
        content: content,
        role: "USER",
        specialty,
        brandVoice,
        timestamp: new Date(),
        onboard: false,
        files: uploadedFileUri ? [uploadedFileUri] : [],
      };

      await handleCreateUserMessage(message);
    } catch (error) {
      console.error("Erro em handleUserSendingMessage:", error);
    }
  };

  // Função para mostrar mensagens de espera com duração específica
  const showWaitMessage = (content, duration) => {
    messageApi.open({
      key: WAIT_MESSAGE_KEY,
      type: "loading",
      content: content,
      duration: duration / 1000, // Converter milissegundos para segundos
    });
  };

  const handleOpenAIMessage = async (userMessage) => {
    const message = {
      idChat: idChat,
      content: "",
      role: "SYSTEM",
      specialty,
      brandVoice,
      timestamp: new Date(),
      onboard: false,
    };

    setAIWriting(true);
    onAIWriting(true);
    startMessageDelayWarningTimeout();

    // Variável para armazenar os IDs dos timeouts das mensagens de espera
    const waitMessageTimeouts = [];

    // Definir as mensagens de espera com seus atrasos e durações
    const waitMessages = [
      { delay: 0, duration: 5000, content: "Estamos analisando seu arquivo, isso pode levar alguns instantes..." },
      { delay: 10000, duration: 5000, content: "Preparando uma resposta incrível para você..." },
      { delay: 20000, duration: 5000, content: "Seu conteúdo está quase pronto!" },
    ];

    try {
      const reader = await CompletionRemote.getReader({
        idChat: message.idChat,
        content: userMessage.content,
        specialty: message.specialty,
        brandVoice: message.brandVoice,
        generationModelClass: generationModelClass,
        files: userMessage.files,
      });

      if (!reader) {
        throw new Error("Reader not returned by CompletionRemote.getReader");
      }

      // Parar o aviso de atraso inicial
      stopMessageDelayWarningTimeout();

      // Agendar mensagens de espera com pausas
      waitMessages.forEach((waitMsg) => {
        const timeoutId = setTimeout(() => {
          showWaitMessage(waitMsg.content, waitMsg.duration);
        }, waitMsg.delay);
        waitMessageTimeouts.push(timeoutId);
      });

      handleCreateSystemMessage(message);

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const text = new TextDecoder("utf-8").decode(value);
        message.content += text;
        message.timestamp = new Date();
        handleUpdateOngoingSystemMessage(message);
        handleScrollDown();
      }
    } catch (error) {
      console.error("Error in handleOpenAIMessage:", error);
      openRequestErrorNotification();
    } finally {
      // Limpar os timeouts das mensagens de espera
      waitMessageTimeouts.forEach((timeoutId) => clearTimeout(timeoutId));
      messageApi.destroy(WAIT_MESSAGE_KEY);
      messageApi.destroy(MESSAGE_DELAY_WARNING_KEY);
    }

    handleUpdateReadySystemMessage(message);
    setUploadedFileType(null);

    if (!isMobile) {
      if (textInputRef && textInputRef.current)
        textInputRef.current.delayedFocus();
    }
    setAIWriting(false);
    onAIWriting(false);
  };

  const memoizedHandleArtifactPreview = useCallback(
    (codeSnippet) => {
      console.log("Artifact preview requested:", codeSnippet);
      setSelectedCodeSnippet(codeSnippet);
      if (onArtifactPreview) {
        onArtifactPreview(codeSnippet);
      }
    },
    [onArtifactPreview]
  );

  const memoizedHandleStartTypingOnboardingMessage = useCallback(() => {
    setAIWriting(true);
  }, []);

  const memoizedHandleFinishTypingOnboardingMessage = useCallback(() => {
    setAIWriting(false);
  }, []);

  const memoizedHandleScrollDown = useCallback(() => {
    handleScrollDown();
  }, [handleScrollDown]);

  const handleArtifactPreview = (codeSnippet) => {
    console.log("Artifact preview requested:", codeSnippet);
    setSelectedCodeSnippet(codeSnippet);
  };

  const renderArtifactViewer = () => {
    return (
      <>
        {selectedCodeSnippet && (
          <ArtifactViewer
            visible={!!selectedCodeSnippet}
            codeContent={selectedCodeSnippet.content || ""}
            codeLanguage={selectedCodeSnippet.language || "javascript"}
            onClose={() => {
              console.log("Closing ArtifactViewer");
              setSelectedCodeSnippet(null);
            }}
          />
        )}
      </>
    );
  };

  const systemUser = {
    id: 'system',
    name: 'CMOs.ai',
    picture: Logo,
  };

  const renderMessages = () => {
    if (_.isEmpty(messages) && !isLoading && isFetched) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {renderEmptyChat()}
        </div>
      );
    }

    return messages.map((message, index) => {
      const uniqueKey = message.idMessage
        ? `${message.idMessage}-${index}`
        : `message-${index}`;

      // Escolher o objeto de usuário apropriado
      const messageUser = message.role === "SYSTEM" ? systemUser : user;
      const messagePicture = message.role === "SYSTEM" ? systemUser.picture : userPicture;

      return (
        <Message
          key={uniqueKey}
          idChat={idChat}
          user={messageUser}
          picture={messagePicture}
          role={message.role}
          specialty={message.specialty}
          brandVoice={message.brandVoice}
          content={message.content}
          onboard={message.onboard}
          timestamp={message.timestamp}
          onArtifactPreview={memoizedHandleArtifactPreview}
          onStartTyping={memoizedHandleStartTypingOnboardingMessage}
          onFinishTyping={memoizedHandleFinishTypingOnboardingMessage}
          scrollDown={memoizedHandleScrollDown}
          isMobile={isMobile}
          files={message.files}
        />
      );
    });
  };

  const renderEmptyChat = () => {
    return (
      <div className="flex flex-col h-full">
        {renderEmptyChatWelcomeMessage()}
        {renderEmptyChatSuggestions()}
      </div>
    );
  };

  const renderEmptyChatWelcomeMessage = () => {
    return (
      <div className="flex justify-end items-center flex-col h-[60%]">
        <Avatar size={96} src={Logo} />
        <label className="ImageGeneratorExample w-full">
          Como posso te ajudar hoje?
        </label>
      </div>
    );
  };

  const renderEmptyChatSuggestions = () => {
    if (isMobile) {
      return (
        <div className="flex flex-col gap-2 justify-center my-6">
          <SuggestionBox
            text={"Escreva um blogpost sobre inteligência artificial aplicado ao marketing"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Dê 10 sugestões de publicações sobre o tema 'Inbound Marketing'"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Crie uma estratégia para aumentar o engajamento no Instagram"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Faça uma lista com 10 estratégias de marketing digital"}
            onClick={handleUserSendingMessage}
          />
        </div>
      );
    }

    return (
      <div className="flex flex-col gap-4 justify-center h-[40%]">
        <div className="flex gap-4">
          <SuggestionBox
            text={"Escreva um blogpost sobre inteligência artificial aplicado ao marketing"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Dê 10 sugestões de publicações sobre o tema 'Inbound Marketing'"}
            onClick={handleUserSendingMessage}
          />
        </div>
        <div className="flex gap-4">
          <SuggestionBox
            text={"Crie uma estratégia para aumentar o engajamento no Instagram"}
            onClick={handleUserSendingMessage}
          />
          <SuggestionBox
            text={"Faça uma lista com 10 estratégias de marketing digital"}
            onClick={handleUserSendingMessage}
          />
        </div>
      </div>
    );
  };

  const renderSpecialtyFeatureDrawer = () => {
    return (
      <Drawer
        title="O que são as especialidades?"
        placement="right"
        onClose={hideSpecialtyFeatureDrawer}
        open={showSpecialtyFeatureDrawer}
        style={{ fontFamily: "Outfit" }}
        width={isMobile ? "80%" : 650}
      >
        <p>
          O recurso de especialidades permite que o usuário defina o tom e o escopo das respostas em seu chat,
          personalizando a experiência do usuário de acordo com a especialidade escolhida.
        </p>

        <p>
          Cada especialidade tem sua própria abordagem e conhecimento específico, proporcionando respostas mais
          precisas e relevantes para o usuário.
        </p>
      </Drawer>
    );
  };

  const renderSpecialtySelect = () => {
    const items = getSpecialtyItems();

    return (
      <div className="flex flex-row gap-2 justify-center items-center">
        <Dropdown
          type="primary"
          size="small"
          menu={{ items }}
          trigger={['click']}
          className="rounded-full"
          placement="topRight"
          arrow
          dropdownRender={(menu) => (
            <div className="max-h-[200px] overflow-auto">
              {menu}
            </div>
          )}
        >
          <PremiumButton innerClasses="rounded-full">
            <FontAwesomeIcon icon={faUserTie} className="text-white mr-2" />
            Especialidade
          </PremiumButton>
        </Dropdown>
      </div>
    );
  };

  const handleStartTypingOnboardingMessage = () => {
    setAIWriting(true);
  };

  const handleFinishTypingOnboardingMessage = () => {
    setAIWriting(false);
  };

  const renderChatInput = () => {
    const renderUploadedFileInfo = () => {
      if (!uploadedFileName) return null;

      return (
        <div className="uploaded-file-info">
          <Tag
            icon={<FontAwesomeIcon icon={faFile} />}
            color="blue"
            closable
            onClose={() => {
              setUploadedFileUri(null);
              setUploadedFileName(null);
            }}
          >
            {uploadedFileName}
          </Tag>
        </div>
      );
    };

    const renderFileUploadButton = () => {
      return (
        <>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Tooltip title={!isGeminiModel ? "O upload de arquivos está disponível apenas para o modelo Gemini" : ""}>
            <Button
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
              disabled={!isGeminiModel || isAIWritingState || isUploadingFile}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <FontAwesomeIcon icon={faUpload} />
              <span style={{ marginLeft: 8 }}>Upload</span>
              {isGeminiModel && (
                <Tooltip title={`Tipos de arquivos permitidos: ${allowedFileTypesString}`}>
                  <QuestionCircleOutlined
                    style={{ marginLeft: 8, cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation(); // Evita que o clique no ícone acione o upload
                    }}
                  />
                </Tooltip>
              )}
            </Button>
          </Tooltip>
        </>
      );
    };

    const renderFileUploadSection = () => {
      if (isUploadingFile) {
        return (
          <div className="uploading-file-info">
            <Spin tip="Enviando arquivo..." />
          </div>
        );
      }

      return renderUploadedFileInfo();
    };

    if (isMobile) {
      return (
        <div className="flex flex-col w-[95%] gap-2 mb-2 mt-2">
          <div className="w-full flex flex-row items-center">
            <div className="flex flex-row flex-grow items-center justify-start gap-2">
              <BrandVoiceSelector
                brandVoice={brandVoice}
                onChange={handleBrandVoiceChangeLocal}
              />

              {renderFileUploadButton()}
            </div>

            <div className="flex justify-end">
              <div className="flex items-center content-center gap-2">
                <SpecialtySelector
                  idSpecialty={specialty?.idSpecialty}
                  onChange={handleSpecialtyChangeLocal}
                  isMobile={isMobile}
                />
              </div>
            </div>
          </div>

          {renderFileUploadSection()}

          <div className="w-full">
            <TextInput
              ref={textInputContainerRef}
              onSubmit={handleUserSendingMessage}
              disabled={isAIWritingState || isUploadingFile || (textCredits === 0 && !isWalletLoading)}
              isUploading={isUploadingFile}
              isMobile={isMobile}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="ChatInput flex flex-col w-[75%] mb-4 mt-2">
        <div className="flex flex-row items-center">
          <div className="flex flex-row flex-grow items-center justify-start gap-2">
            <BrandVoiceSelector
              brandVoice={brandVoice}
              onChange={handleBrandVoiceChangeLocal}
            />

            {renderFileUploadButton()}
          </div>

          <div className="flex justify-end">
            <div className="flex items-center content-center gap-2">
              <Tooltip title="Saiba mais sobre as especialidades!">
                <a onClick={displaySpecialtyFeatureDrawer}>
                  <QuestionCircleOutlined className="ChatInputSpecialtyIcon" />
                </a>
              </Tooltip>

              <SpecialtySelector
                idSpecialty={specialty?.idSpecialty}
                onChange={handleSpecialtyChangeLocal}
              />
            </div>
          </div>
        </div>

        {renderFileUploadSection()}

        <div style={{ width: "100%" }} ref={textInputContainerRef}>
          <TextInput
            ref={textInputContainerRef}
            onSubmit={handleUserSendingMessage}
            disabled={isAIWritingState || isUploadingFile || (textCredits === 0 && !isWalletLoading)}
            isUploading={isUploadingFile}
          />
        </div>
      </div>
    );
  };

  const renderSpecialtyModalMobile = () => {
    if (!isMobile) {
      return null;
    }

    return (
      <Modal
        open={showSpecialtyModalMobile}
        title="Escolha a especialidade"
        onOk={() => {}}
        onCancel={() => setShowSpecialtyModalMobile(false)}
        width={300}
        centered
        closable={false}
        footer={null}
      >
        {renderSpecialtySelect()}
      </Modal>
    );
  };

  return (
    <>
      {messageContextHolder}
      {notificationContextHolder}
      <div className="w-full flex flex-row items-center justify-between mx-4 mr-10 my-2">
        <GenerationModelClassSelector />
        <div className="mr-8">
          <CreditsCounter />
        </div>
      </div>
      <Layout className="Chat w-full flex flex-col" ref={chatContainerRef}>
        <Content
          className="ChatContainer flex-1"
          ref={chatBox}
          onScroll={handleScrollDetector}
        >
          {renderMessages()}
        </Content>
        {renderChatInput()}
        {renderSpecialtyFeatureDrawer()}
        {renderSpecialtyModalMobile()}
      </Layout>
      <NoMoreTextCreditsModal
        hide={hideNoMoreTextCreditsModal}
        onClose={() => setHideNoMoreTextCreditsModal(true)}
      />
      {renderArtifactViewer()}
    </>
  );
};

// Definir PropTypes para ChatRenderer
ChatRenderer.propTypes = {
  idChat: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  specialty: PropTypes.object,
  brandVoice: PropTypes.object,
  onSpecialtyChange: PropTypes.func.isRequired,
  onBrandVoiceChange: PropTypes.func.isRequired,
  onAIWriting: PropTypes.func.isRequired,
  onMessageSent: PropTypes.func.isRequired,
  onArtifactPreview: PropTypes.func.isRequired,
};

export default ChatRenderer;
