import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Step2.css';

const Step2 = ({ onNext, onSkip }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const targetElement = document.querySelector('.generation-model-class-selector');
      if (targetElement) {
        const rect = targetElement.getBoundingClientRect();
        setPosition({
          top: rect.top + window.scrollY - 12,
          left: rect.right + window.scrollX + 10,
        });
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleNext = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onNext();
    }, 300); // Duração da animação em milissegundos
  }, [onNext]);

  const handleSkip = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onSkip();
    }, 300); // Duração da animação em milissegundos
  }, [onSkip]);

  return (
    <div
      className={`onboarding-step2 ${isClosing ? 'closing' : ''}`}
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      <div className="balloon">
        <span className="step-number">2</span>
        <p>Selecione aqui o modelo mais adequado para cada tipo de tarefa que você precisa realizar.</p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Próximo</button>
        </div>
      </div>
    </div>
  );
};

Step2.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

export default Step2;

