// src/components/chat/SpecialtySelector.jsx

import React, { useEffect, useRef, useState } from "react";
import { Input, Modal } from "antd";
import PremiumButton from "../buttons/PremiumButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { usePublishedSpecialties } from "../../utils/hooks/useSpecialties";
import classNames from "classnames";
import { faRedhat } from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Importação do ícone de fechamento

const SpecialtySelector = ({ idSpecialty, onChange, isMobile = false }) => {
  const { publishedSpecialties, isLoading } = usePublishedSpecialties();
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [idSpecialtySelected, setIdSpecialtySelected] = useState(idSpecialty || null);

  const searchBarRef = useRef(null);

  useEffect(() => {
    if (!isLoading && !_.isEmpty(publishedSpecialties)) {
      setIdSpecialtySelected(idSpecialty || null);
    }
  }, [idSpecialty, isLoading, publishedSpecialties]);

  const getSpecialtyItems = () => {
    if (_.isEmpty(publishedSpecialties)) {
      return [];
    }

    let specialtiesToReturn = publishedSpecialties;

    if (!_.isEmpty(filter)) {
      specialtiesToReturn = specialtiesToReturn.filter((specialty) =>
        specialty.name.toLowerCase().includes(filter.toLowerCase())
      );
    }

    // Ordenar specialtiesToReturn pelo nome em ordem ascendente
    specialtiesToReturn = _.orderBy(specialtiesToReturn, ["name"], ["asc"]);

    return specialtiesToReturn;
  };

  const getSpecialtyById = (idSpecialty) => {
    if (_.isEmpty(publishedSpecialties)) {
      return null;
    }

    return publishedSpecialties.find((specialty) => specialty.idSpecialty === idSpecialty);
  };

  const translateSpecialtyName = (idSpecialty) => {
    if (_.isEmpty(publishedSpecialties) || _.isEmpty(idSpecialty)) {
      return "Especialidade";
    }

    const specialty = getSpecialtyById(idSpecialty);
    if (_.isEmpty(specialty)) {
      return "Especialidade";
    }

    return specialty.name;
  };

  const setDefaultSelected = () => {
    if (!_.isEmpty(idSpecialty)) {
      setIdSpecialtySelected(idSpecialty);
    } else {
      setIdSpecialtySelected(null);
    }
  };

  // Função para deselecionar a especialidade
  const handleClearSelection = (e) => {
    e.stopPropagation(); // Impede que o modal seja aberto
    setIdSpecialtySelected(null);
    onChange(null);
  };

  const renderSearchBar = () => {
    return (
      <div className="flex flex-row gap-2 items-center my-4">
        <Input
          ref={searchBarRef}
          placeholder="Pesquisar especialidade"
          size="large"
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    );
  };

  const renderSpecialtyList = () => {
    return (
      <div className={"flex flex-col gap-2 overflow-auto max-h-[50vh]"}>
        {getSpecialtyItems().map((item) => {
          return (
            <div
              key={item.idSpecialty}
              className={classNames(
                "flex flex-row gap-2 items-center px-4 py-2 rounded-lg mr-2",
                {
                  "bg-primary-active text-white": idSpecialtySelected === item.idSpecialty,
                  "hover:bg-primary-bg-hover hover:cursor-pointer":
                    idSpecialtySelected !== item.idSpecialty,
                }
              )}
              onClick={() => setIdSpecialtySelected(item.idSpecialty)}
            >
              <div className="">{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderLabel = () => {
    return translateSpecialtyName(idSpecialty);
  };

  return (
    <>
      <div className="specialty-selector flex flex-row gap-2 justify-center items-center">
        <PremiumButton
          className="rounded-full"
          size="medium"
          onClick={() => {
            setOpenModal(true);
            setTimeout(() => searchBarRef.current?.focus(), 100);
          }}
        >
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={faRedhat}
              className={classNames({ "mr-2 text-white": !isMobile })}
            />
            {!isMobile && renderLabel()}
            {!_.isEmpty(idSpecialty) && (
              <FontAwesomeIcon
                icon={faTimes}
                className="ml-2 text-white cursor-pointer"
                onClick={handleClearSelection}
              />
            )}
          </div>
        </PremiumButton>
      </div>

      <Modal
        title="Especialidades"
        open={openModal}
        okText={"Selecionar"}
        cancelText={"Cancelar"}
        onOk={() => {
          setOpenModal(false);
          if (!_.isEmpty(idSpecialtySelected)) {
            onChange(getSpecialtyById(idSpecialtySelected));
          }
        }}
        onCancel={() => {
          setOpenModal(false);
          setDefaultSelected();
        }}
      >
        {renderSearchBar()}
        {renderSpecialtyList()}
      </Modal>
    </>
  );
};

export default SpecialtySelector;
