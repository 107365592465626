// src\app\content-creator\components\ContentCreatorSpecialtiesList.jsx
import React from 'react';
import { List } from "antd";
import ContentCreatorSpecialtiesListItem from "./ContentCreatorSpecialtiesListItem";
import { useSpecialties } from "../../../utils/hooks/useSpecialties";
import _ from "lodash";

const ContentCreatorSpecialtiesList = ({ filter }) => {
    const { specialties, isLoading: isSpecialtiesLoading } = useSpecialties();

    const getFilteredSpecialties = () => {
        let filteredSpecialties = specialties;

        if (filter) {
            filteredSpecialties = filteredSpecialties.filter((s) => {
                return s.name.toLowerCase().includes(filter.toLowerCase())
                    || s.description.toLowerCase().includes(filter.toLowerCase());
            });
        }

        return _.orderBy(filteredSpecialties, ["name"], ["asc"]);
    };

    const renderHeader = () => {
        return (
            <div className="px-4 font-sans">
                <div className="text-lg">
                    Especialidades
                </div>
            </div>
        );
    };

    const renderItem = (item) => {
        return (
            <ContentCreatorSpecialtiesListItem item={item} />
        );
    };

    return (
        <div className="my-4">
            <List
                loading={isSpecialtiesLoading}
                itemLayout="horizontal"
                header={renderHeader()}
                dataSource={getFilteredSpecialties()}
                size="large"
                renderItem={renderItem}
                bordered
                pagination={{
                    pageSize: 7
                }}
            />
        </div>
    );
};

export default ContentCreatorSpecialtiesList;
