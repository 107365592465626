import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Step5.css';

const Step5 = ({ onNext, onSkip }) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const targetElement = document.querySelector('.brandvoice-selector');
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                const balloonWidth = 280;
                const balloonHeight = 132;
                const offset = 10;

                setPosition({
                    top: rect.top + window.scrollY - balloonHeight - offset,
                    left: rect.left + window.scrollX + (rect.width / 2) - (balloonWidth / 2),
                });
            }
        }, 0);

        return () => clearTimeout(timeoutId);
    }, []);

    const handleNext = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            onNext();
        }, 300);
    }, [onNext]);

    const handleSkip = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            onSkip();
        }, 300);
    }, [onSkip]);

    return (
        <div
            className={`onboarding-step5 ${isClosing ? 'closing' : ''}`}
            style={{
                top: position.top,
                left: position.left,
            }}
        >
            <div className="balloon">
                <span className="step-number">5</span>
                <p>
                    Para respostas com a mesma linguagem seja na marca ou pessoa, escolha aqui o 'brand voice' que o modelo deve considerar.
                </p>
                <div className="actions">
                    <button onClick={handleNext} className="next-button">Concluir</button>
                </div>
            </div>
        </div>
    );
};

Step5.propTypes = {
    onNext: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
};

export default Step5;

