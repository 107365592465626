import { Avatar, Card, Popover, Skeleton } from "antd";
import "./ToolCard.css";
import { faArrowTrendUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import UserPreferencesRemote from "../../services/dao/remote/userPreferences";
import UpgradeButton from "../subscription/UpgradeButton";

const ToolCard = ({ idTool, title, description, badge, icon, loading, onClick, onPin, isPinned, disabled }) => {

    const pinTool = useMutation({
        mutationFn: (idTool) =>
            UserPreferencesRemote.pinTool(idTool)
    });

    const handlePin = (event) => {
        event.stopPropagation();
        onPin(idTool);
        // pinTool.mutate(idTool);
    }

    const renderNewBadge = () => {
        return (
            <div className="BadgeContainer">
                <div className="Badge BadgeNew">
                    <FontAwesomeIcon className="BadgeIcon" icon={faStar} />
                    <span className="BadgeText">Novo</span>
                </div>
            </div>
        );
    }

    const renderPopularBadge = () => {
        return (
            <div className="BadgeContainer">
                <div className="Badge BadgePopular">
                    <FontAwesomeIcon className="BadgeIcon" icon={faArrowTrendUp} />
                    <span className="BadgeText">Popular</span>
                </div>
            </div>
        );
    }

    const renderBadge = () => {
        if (badge === "new") {
            return renderNewBadge();
        } else if (badge === "popular") {
            return renderPopularBadge();
        }
    }

    const renderPinIcon = () => {
        return (
            <div
                className={`ToolCardPin ${isPinned ? "ToolCardPinActive" : ""}`}
                onClick={handlePin}
            >
                <FontAwesomeIcon className="ToolCardPinIcon" icon={isPinned ? faStar : faStarRegular} />
            </div>
        );
    }

    if (loading) {
        return (
            <Card className="ToolCard">
                <Skeleton avatar active />
            </Card>
        );
    }

    const renderIcon = () => {
        return (
            <Avatar
                shape={"square"}
                size={52}
                src={process.env.REACT_APP_TOOL_ICON_S3_BUCKET_PREFIX_URL + icon}
            />
        );
    }

    const renderTitle = () => {
        return (
            <div className={`ToolCardTitle ${disabled && "text-gray-400"}`}>
                {title}
            </div>
        );
    }

    const renderDescription = () => {
        return (
            <div
                className={`ToolCardDescription transition-colors duration-200 ease-in-out ${!disabled && "group-hover:text-primary"}`}>
                {description}
            </div>
        );
    }

    const renderPopoverContent = () => {
        return (
            <div className="font-primary w-[200px] flex flex-col gap-2">
                Para acessar esta ferramenta e muitas outras, faça um upgrade de plano.
                <UpgradeButton size="large" />
            </div>
        )
    }

    const component = (
        <Card
            className={`ToolCard ${disabled && "bg-[#f5f5f500] border-gray-300 text-gray-400 hover:cursor-not-allowed group"}`}
            onClick={() => !disabled && onClick(idTool)}
        >
            {renderIcon()}
            {renderTitle()}
            {renderDescription()}
            {renderBadge()}
        </Card>
    );

    if (disabled) {
        // Ajuste mínimo: envolver o Popover em um <div> com a classe "ToolCard"
        return (
            <div className="ToolCard">
                <Popover
                    content={renderPopoverContent()}
                    trigger="hover"
                    placement="right"
                >
                    {component}
                </Popover>
            </div>
        )
    } else {
        return component;
    }
}

export default ToolCard;
