import React, {useEffect, useState} from 'react';
import {useGenerationModelClasses} from "../../utils/hooks/useGenerationModels";
import {Avatar, Button, Dropdown, Popover, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import OpenAIIcon from "../../images/icons/openai.svg";
import AnthropicIcon from "../../images/icons/anthropic.jpeg";
import {useGenerationModelClass} from "../../utils/hooks/useGenerationModelClass";
import UpgradeButton from "../../components/subscription/UpgradeButton";
import {useCurrentSubscriptionPlans} from "../../utils/hooks/useCurrentSubscriptionPlan";
import {Theme} from "../../api/Theme";
import GoogleIcon from "../../images/icons/google.png";

const GenerationModelClassSelector = () => {
    const [selectedGenerationModelClass, setSelectedGenerationModelClass] = useState(null);

    const {generationModelClasses, isLoading} = useGenerationModelClasses();
    const {generationModelClass, setGenerationModelClass} = useGenerationModelClass();

    const {currentSubscriptionPlans} = useCurrentSubscriptionPlans();

    const [currentGenerationModelClass, setCurrentGenerationModelClass] = useState(null);

    useEffect(() => {
        setCurrentGenerationModelClass(generationModelClass);

        if (selectedGenerationModelClass === null) {
            setSelectedGenerationModelClass(generationModelClass);
        }

    }, [generationModelClass, selectedGenerationModelClass]);

    const handleGenerationModelClassChange = (model) => {
        if (isGenerationModelClassAvailable(model)) {
            setGenerationModelClass(model);
            setSelectedGenerationModelClass(model);
        }
    }

    const renderOptionLabel = (model) => {
        const component = (
            <div className="my-1 font-primary" onClick={() => handleGenerationModelClassChange(model.value)}>
                <div className="flex flex-row items-center gap-2">
                    <Avatar shape="square" src={getOptionIcon(model.value)}/>

                    <div>
                        <div className="flex items-center">
                            <div className="text-reg max-w-[250px]">
                                {model.name}
                            </div>
                        </div>
                        <div className="text-xsm text-gray-400 max-w-[250px] whitespace-normal">
                            {model?.description}
                        </div>
                    </div>
                </div>
            </div>
        );

        if (!isGenerationModelClassAvailable(model.value)) {
            return (
                <Popover
                    content={renderPopoverContent()}
                    trigger="hover"
                    placement="right"
                >
                    {component}
                </Popover>
            );
        } else {
            return component;
        }
    }

    const isGenerationModelClassAvailable = (model) => {
        return (currentSubscriptionPlans?.map(subscriptonPlan => subscriptonPlan.generationModelClasses).flat() || []).includes(model);
    }

    const getDropdownItems = () => {
        if (isLoading || !generationModelClasses) {
            return [];
        }

        return generationModelClasses.map(model => {
            return {
                label: renderOptionLabel(model),
                key: model?.value,
                disabled: !isGenerationModelClassAvailable(model?.value)
            };
        });
    }

    const getOptionIcon = (model) => {
        if (!model) {
            return null;
        }
    
        if (model.startsWith("gpt")) {
            return OpenAIIcon;
        } else if (model.startsWith("claude")) {
            return AnthropicIcon;
        } else if (model.startsWith("gemini")) {
            return GoogleIcon; // Retorna o ícone do Google para modelos Gemini
        } else {
            return (
                <Avatar className="bg-premium" shape="square">
                    <FontAwesomeIcon icon={faWandMagicSparkles}/>
                </Avatar>
            );
        }
    }

    const getSelectedGenerationModelClass = (model) => {
        if (isLoading || !generationModelClasses) {
            return null;
        }

        return generationModelClasses.find(generationModelClass => generationModelClass.value === model);
    }

    const renderPopoverContent = () => {
        return (
            <div className="font-primary w-[200px] flex flex-col gap-2">
                Para acessar modelos mais avançados, faça um upgrade de plano.
                <UpgradeButton size="large"/>
            </div>
        )
    }

    return (
        <div className="generation-model-class-selector flex flex-row items-center gap-2">
            <Dropdown menu={{items: getDropdownItems()}} trigger={['click']}>
                <Button
                    type="text"
                    size="large"
                    className="text-gray-800"
                >
                    <Avatar shape="square" src={getOptionIcon(getSelectedGenerationModelClass(currentGenerationModelClass)?.value)}/>
                    {getSelectedGenerationModelClass(currentGenerationModelClass)?.name}
                    <FontAwesomeIcon icon={faChevronDown}/>
                </Button>
            </Dropdown>
            <Tag color={Theme.PRIMARY}>Novo</Tag>
        </div>
    );
};

export default GenerationModelClassSelector;