// src/components/onboarding/imagens/Step2.jsx

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './Step2.css';

const ImagesStep2 = ({ onNext }) => {
  const [position, setPosition] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Novo estado para visibilidade
  const balloonRef = useRef(null);

  useEffect(() => {
    const adjustPosition = () => {
      // Seleciona o botão 'Melhorar descrição'
      const enhanceButton = document.querySelector('.ImageGeneratorEnhanceButton');
      if (enhanceButton && balloonRef.current) {
        const rect = enhanceButton.getBoundingClientRect();
        const balloon = balloonRef.current;
        const balloonWidth = balloon.offsetWidth; // Usando o valor atual
        const balloonHeight = balloon.offsetHeight; // Usando o valor atual

        // Posiciona o balão no lado superior esquerdo do botão
        let top = rect.top + window.scrollY - balloonHeight - 20; // Ajuste vertical
        let left = rect.left + window.scrollX + rect.width - balloonWidth + 10; // Ajuste horizontal

        setPosition({ top, left });
      } else {
        // Se os elementos não foram encontrados, tenta novamente após um pequeno atraso
        setTimeout(adjustPosition, 100);
      }
    };

    // Ajusta a posição
    adjustPosition();

    // Adiciona a classe 'show' para a animação de entrada
    setTimeout(() => {
      setIsVisible(true);
    }, 100);

    // Atualiza a posição em caso de redimensionamento
    window.addEventListener('resize', adjustPosition);

    return () => {
      window.removeEventListener('resize', adjustPosition);
    };
  }, []);

  const handleNext = () => {
    setIsVisible(false); // Remove a classe para a animação de saída
    setTimeout(() => {
      onNext(); // Chama a próxima etapa após a animação
    }, 500);
  };

  return (
    <div
      className={`images-onboarding-step2 ${isVisible ? 'show' : ''}`}
      style={{
        top: position ? position.top : -9999,
        left: position ? position.left : -9999,
      }}
    >
      <div className="images-step2-balloon" ref={balloonRef}>
        <span className="step-number">2</span>
        <p>
          Use o poder da nossa IA para melhorar a descrição da sua imagem e obter resultados ainda mais incríveis.
        </p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Entendi</button>
        </div>
      </div>
    </div>
  );
};

ImagesStep2.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default ImagesStep2;
