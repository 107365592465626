// src/components/chat/ChatHistory.jsx

import React, { Component } from "react";
import "./ChatHistory.css";
import ChatHistoryItem from "./ChatHistoryItem";
import autoBind from "auto-bind";
import _ from "lodash";
import Sider from "antd/es/layout/Sider";
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import withIsMobile from "../../utils/hocs/withIsMobile";
import PremiumButton from "../buttons/PremiumButton";

class ChatHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isCollapsed: false,
        };

        autoBind(this);
    }

    onCollapse() {
        this.setState((prevState) => ({
            isCollapsed: !prevState.isCollapsed,
        }));
    }

    renderChatItems() {
        const { isCollapsed } = this.state;
        const { chats, selectedChatId, onChatChange, onChatDelete, onChatRename, disabled } = this.props;
        return _.orderBy(
            chats,
            [(chat) => {
                return String(chat.updatedAt);
            }],
            ["desc"]
        ).map((chat) => {
            return (
                <ChatHistoryItem
                    key={chat.idChat}
                    title={chat.title}
                    idChat={chat.idChat}
                    isSelected={chat.idChat === selectedChatId}
                    icon={<FontAwesomeIcon icon="fa-solid fa-user" />}
                    onChatChange={onChatChange}
                    onChatDelete={onChatDelete}
                    onChatRename={onChatRename}
                    collapsed={isCollapsed} // Pass the isCollapsed state to ChatHistoryItem
                    disabled={disabled}
                />
            );
        });
    }

    renderCreateNewChatButton() {
        const { onChatCreate, disabled } = this.props;

        return (
            <PremiumButton
                innerClasses="rounded-full my-1"
                block
                icon={<FontAwesomeIcon icon={faPlus} className="mr-2" />}
                onClick={disabled ? null : onChatCreate}
            >
                {"Novo chat"}
            </PremiumButton>
        );
    }

    renderCollapseButton() {
        const { isCollapsed } = this.state;

        return (
            <Button
                shape="circle"
                size="large"
                type="text"
                icon={
                    isCollapsed ? (
                        <FontAwesomeIcon icon={faChevronRight} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronLeft} />
                    )
                }
                onClick={this.onCollapse}
                className="ChatHistoryCollapseButton"
            />
        );
    }

    render() {
        const { isCollapsed } = this.state;
        const { isMobile, isLoading } = this.props;
        const position = isMobile && !isCollapsed ? "absolute" : "relative";

        return (
            <>
                <Sider
                    className={`ChatHistorySider ${isCollapsed ? 'collapsed' : ''} bg-indigo-950 border-solid border-r-[1px] border-t-0 border-l-0 border-b-0 border-common-border rounded-l-lg p-4 z-10`}
                    style={{ height: "calc(100% - 24px)", marginTop: "12px", position: position }}
                    width={220}
                    breakpoint="md"
                    collapsible
                    collapsed={isCollapsed}
                    trigger={null}
                >
                    {this.renderCollapseButton()}
                    {!isCollapsed && (
                        <>
                            {this.renderCreateNewChatButton()}
                            <div className={"h-[16px]"} />
                            {isLoading && (
                                <div className="w-full flex justify-center text-center p-2">
                                    <LoadingOutlined className="text-white" spin style={{ fontSize: 36 }} />
                                </div>
                            )}
                            {!isLoading && (
                                <div className="overflow-y-auto pb-4 scrollbar-hide" style={{ height: "calc(100% - 36px)" }}>
                                    {this.renderChatItems()}
                                </div>
                            )}
                        </>
                    )}
                </Sider>
            </>
        );
    }
}

export default withIsMobile(ChatHistory);
