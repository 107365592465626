import WorkspaceInvitationDAORemote from "../../services/dao/remote/WorkspaceInvitationDAORemote";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useCurrentWorkspace} from "./useCurrentWorkspace";
import {message} from "antd";
import {useEffect, useState} from "react";

export const useWorkspaceInvitations = () => {
    const {currentWorkspace} = useCurrentWorkspace();
    const {data: invitations, isLoading} = useQuery({
        queryKey: ["workspaceInvitations", currentWorkspace.idWorkspace],
        queryFn: () => WorkspaceInvitationDAORemote.getAll({idWorkspace: currentWorkspace.idWorkspace})
    });

    const [shareableLinks, setShareableLinks] = useState([]);
    const [emailInvitations, setEmailInvitations] = useState([]);

    useEffect(() => {
        setShareableLinks(invitations?.filter(i => i.type === "SHAREABLE_LINK"));
        setEmailInvitations(invitations?.filter(i => i.type === "EMAIL"));
    }, [invitations]);

    return {shareableLinks, emailInvitations, isLoading};
}

export const useGenerateWorkspaceInvitation = ({onSuccess, onError} = {}) => {
    const {currentWorkspace} = useCurrentWorkspace();
    const queryClient = useQueryClient();

    const {mutate: generateInvitation, isLoading: isGenerating} = useMutation({
        mutationFn: ({idWorkspace}) => WorkspaceInvitationDAORemote.generate({idWorkspace}),
        onSuccess: (workspaceInvitation) => {
            queryClient.setQueryData(["workspaceInvitations", currentWorkspace.idWorkspace], (invitations) => {
                return [...invitations, workspaceInvitation];
            });
            onSuccess?.();
        },
        onError: (error) => {
            onError?.(error);
        }
    });

    return {generateInvitation, isGenerating};
}

export const useSendWorkspaceInvitations = ({onSuccess, onError} = {}) => {
    const {currentWorkspace} = useCurrentWorkspace();
    const queryClient = useQueryClient();

    const {mutate: sendInvitations, isLoading: isSending} = useMutation({
        mutationFn: ({idWorkspace, recipients}) => WorkspaceInvitationDAORemote.send({idWorkspace, recipients}),
        onSuccess: (workspaceInvitations) => {
            queryClient.setQueryData(["workspaceInvitations", currentWorkspace.idWorkspace], (invitations) => {
                return [...invitations, ...workspaceInvitations];
            });
            message.success(`Convite enviado para ${workspaceInvitations.map(wi => wi.recipient).join(", ")}`);
            onSuccess?.();
        },
        onError: (error) => {
            onError?.(error);
        }
    });

    return {sendInvitations, isSending};
}

export const useDeleteWorkspaceInvitation = ({onSuccess, onError} = {}) => {
    const {currentWorkspace} = useCurrentWorkspace();
    const queryClient = useQueryClient();

    const {mutate: deleteInvitation, isLoading: isDeleting} = useMutation({
        mutationFn: ({idWorkspace, idWorkspaceInvitation}) => WorkspaceInvitationDAORemote.delete({
            idWorkspace,
            idWorkspaceInvitation
        }),
        onSuccess: (idWorkspaceInvitation) => {
            queryClient.setQueryData(["workspaceInvitations", currentWorkspace.idWorkspace], (invitations) => {
                return invitations.filter((i) => i.idWorkspaceInvitation !== idWorkspaceInvitation);
            });
            message.success("Convite excluído");
            onSuccess?.();
        },
        onError: (error) => {
            onError?.(error);
        }
    });

    return {deleteInvitation, isDeleting};
}