import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Step0.css';

const Step0 = ({ onNext }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleNext = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onNext();
    }, 300); // Duração da animação em milissegundos
  }, [onNext]);

  return (
    <div className={`onboarding-step0 ${isClosing ? 'closing' : ''}`}>
      <div className="overlay"></div>
      <div className="balloon">
        <p>Olá, boas vindas ao CMOS.ai!<br/>Vamos te guiar pela plataforma.</p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Próximo</button>
        </div>
      </div>
    </div>
  );
};

Step0.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default Step0;

