// src/components/onboarding/agents/Step2.jsx

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Step2.css';

const AgentsStep2 = ({ onNext }) => {
  const [position, setPosition] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Novo estado para controle de visibilidade

  useEffect(() => {
    const adjustPosition = () => {
      // Seleciona o item do menu "Conteúdo" no submenu "Agentes"
      const menuItem = document.querySelector('.ant-menu-item[title="Conteúdo"]');
      if (menuItem) {
        const rect = menuItem.getBoundingClientRect();
        const balloon = document.querySelector('.agents-step2-balloon');
        const balloonWidth = balloon ? balloon.offsetWidth : 280; // Usando max-width definido no CSS

        const viewportWidth = window.innerWidth;

        // Posiciona o balão à direita do botão
        let top = rect.top + window.scrollY - 10; // Ajuste vertical
        let left = rect.right + window.scrollX + 20; // Ajuste horizontal para posicionar à direita

        // Verifica se o balão ultrapassa a largura da tela
        if (left + balloonWidth > viewportWidth - 20) {
          // Posiciona o balão à esquerda do botão
          left = rect.left + window.scrollX - balloonWidth - 20;
        }

        setPosition({ top, left });
      } else {
        // Se o elemento não foi encontrado, tenta novamente após um pequeno atraso
        setTimeout(adjustPosition, 100);
      }
    };

    // Chama a função após um pequeno atraso
    setTimeout(adjustPosition, 0);

    // Atualiza a posição em caso de redimensionamento da janela
    window.addEventListener('resize', adjustPosition);

    // Adiciona a classe 'show' para iniciar a animação de entrada
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Pequeno atraso para garantir a transição

    // Limpeza
    return () => {
      window.removeEventListener('resize', adjustPosition);
    };
  }, []);

  // Função para lidar com o clique no botão "Próximo"
  const handleNext = () => {
    setIsVisible(false); // Inicia a animação de saída
    setTimeout(() => {
      onNext(); // Chama a função onNext após a animação
    }, 500); // Tempo deve corresponder à duração da transição (0.5s)
  };

  // Se a posição ainda não foi calculada, não renderiza o balão
  if (!position) {
    return null;
  }

  return (
    <div
      className={`agents-onboarding-step2 ${isVisible ? 'show' : ''}`}
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      <div className="agents-step2-balloon">
        <span className="step-number">2</span>
        <p>
          Também é possível localizar os agentes inteligentes ao selecionar a categoria no menu.
        </p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Próximo</button>
        </div>
      </div>
    </div>
  );
};

AgentsStep2.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default AgentsStep2;
