import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBullhorn} from "@fortawesome/free-solid-svg-icons";
import {Tag, Tooltip} from "antd";
import PremiumButton from "../buttons/PremiumButton";
import BrandVoiceSelectorModal from "./BrandVoiceSelectorModal";

const BrandVoiceSelector = ({brandVoice, onChange}) => {
    const [showBrandVoiceSelectorModal, setShowBrandVoiceSelectorModal] = useState(false);

    const renderSelectedBrandVoice = () => {
        if (!brandVoice) {
            return null;
        }

        return (
            <Tag
                key={brandVoice.idBrandVoice}
                className="font-primary ml-2 px-3 py-1 rounded-lg font-medium"
                closable
                bordered={false}
                onClose={() => onChange(null)}
            >
                {brandVoice?.name}
            </Tag>
        );
    }

    return (
        <>
          <div className="brandvoice-selector">
            <Tooltip title="Brand voice">
                <PremiumButton
                    innerClasses={"rounded-full"}
                    onClick={() => setShowBrandVoiceSelectorModal(true)}
                >
                    <FontAwesomeIcon icon={faBullhorn}/>
                </PremiumButton>
            </Tooltip>

            {renderSelectedBrandVoice()}
        </div>
        
            <BrandVoiceSelectorModal
                open={showBrandVoiceSelectorModal}
                onClose={() => setShowBrandVoiceSelectorModal(false)}
                brandVoice={brandVoice}
                onBrandVoiceApply={(brandVoice) => onChange(brandVoice)}
            />
        </>
    );
};

export default BrandVoiceSelector;