import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Step3.css';

const Step3 = ({ onNext, onSkip }) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const targetElement = document.querySelector('.credits-counter');
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                setPosition({
                    top: rect.top + window.scrollY,
                    left: rect.right + window.scrollX - 450,
                });
            }
        }, 0);

        return () => clearTimeout(timeoutId);
    }, []);

    const handleNext = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            onNext();
        }, 300); // Duração da animação em milissegundos
    }, [onNext]);

    const handleSkip = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            onSkip();
        }, 300); // Duração da animação em milissegundos
    }, [onSkip]);

    return (
        <div
            className={`onboarding-step3 ${isClosing ? 'closing' : ''}`}
            style={{
                top: position.top,
                left: position.left,
            }}
        >
            <div className="balloon">
                <span className="step-number">3</span>
                <p>Você recebe créditos para usar com as suas tarefas no CMOS.ai. Acompanhe aqui o seu consumo.</p>
                <div className="actions">
                    <button onClick={handleNext} className="next-button">Próximo</button>
                </div>
            </div>
        </div>
    );
};

Step3.propTypes = {
    onNext: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
};

export default Step3;

