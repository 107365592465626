// src/components/message/Message.jsx

import React, { useEffect, useRef, useState, useMemo } from "react";
import "./Message.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // Suporte para Markdown no estilo GitHub
import rehypeHighlight from "rehype-highlight"; // Realce de sintaxe
import rehypeSanitize, { defaultSchema } from "rehype-sanitize"; // Sanitização
import { Specialties } from "../../api/specialties";
import { Avatar, Tag, Button, Modal } from "antd";
import Logo from "../../images/message.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone, faFilePdf, faFileAlt, faImage } from "@fortawesome/free-regular-svg-icons";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faRedhat } from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";
import "prismjs/themes/prism.css"; // Tema do Prism.js
import { Tooltip } from "react-tooltip";

// Definir componentes personalizados
const Type = React.memo((props) => <div {...props} />);
const Content = React.memo((props) => <div {...props} />);
const Artifact = React.memo((props) => <div {...props} />);

const customSchema = {
  ...defaultSchema,
  tagNames: [...defaultSchema.tagNames, "type", "content", "artifact"],
  attributes: {
    ...defaultSchema.attributes,
    type: ["*"],
    content: ["*"],
    artifact: ["*"],
  },
};

const Message = ({
  idChat,
  role,
  content,
  timestamp,
  onboard = false,
  user,
  picture,
  specialty,
  files, // Adicionado o prop 'files'
  brandVoice,
  isMobile = false,
  onArtifactPreview,
  onStartTyping = () => {},
  onFinishTyping = () => {},
  scrollDown = () => {},
}) => {
  const [typedMessage, setTypedMessage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null); // Renomeado para 'previewImage'
  const typeTimer = useRef(null);

  const codeSnippet = useMemo(() => detectCodeSnippet(content), [content]);

  useEffect(() => {
    if (onboard) {
      typeText(content);
    }
    return () => {
      if (typeTimer.current) {
        clearInterval(typeTimer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboard, content]);

  const getSpecialtyName = () => {
    const specialtyItem = Specialties.find((item) => item.id === specialty);

    if (specialtyItem) {
      return (specialtyItem.suffix ? specialtyItem.suffix : "") + specialtyItem.name;
    } else {
      return "Nenhuma especialidade atribuída";
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(content);
  };

  const renderRegularMessage = (text) => {
    return (
      <span className="MessageText">
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSanitize(customSchema), rehypeHighlight]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <pre className={`language-${match[1]}`}>
                  <code className={`language-${match[1]}`} {...props}>
                    {children}
                  </code>
                </pre>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            type: (props) => <Type {...props} />,
            content: (props) => <Content {...props} />,
            artifact: (props) => <Artifact {...props} />,
          }}
        >
          {text}
        </ReactMarkdown>
      </span>
    );
  };

  const renderOnboardingMessage = () => {
    return (
      <div>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeSanitize(customSchema), rehypeHighlight]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              return !inline && match ? (
                <pre className={`language-${match[1]}`} {...props}>
                  <code className={`language-${match[1]}`}>{children}</code>
                </pre>
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            },
            type: (props) => <Type {...props} />,
            content: (props) => <Content {...props} />,
            artifact: (props) => <Artifact {...props} />,
          }}
        >
          {typedMessage}
        </ReactMarkdown>
      </div>
    );
  };

  const messageContent = useMemo(() => {
    if (onboard) {
      return renderOnboardingMessage();
    } else {
      return renderRegularMessage(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboard, content, typedMessage]);

  const renderFiles = () => {
    if (!files || files.length === 0) return null;
  
    return (
      <div className="MessageFiles">
        {files.map((fileUrl, index) => {
          const fileName = getFileNameFromUrl(fileUrl);
          const fileExtension = getFileExtension(fileUrl).toLowerCase();
  
          // Definir ícone baseado na extensão
          let fileIcon;
          if (["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(fileExtension)) {
            fileIcon = faImage; // Ícone de imagem para arquivos de imagem
          } else if (["pdf"].includes(fileExtension)) {
            fileIcon = faFilePdf;
          } else {
            fileIcon = faFileAlt; // Ícone genérico para outros tipos de arquivos
          }
  
          // Não há necessidade de tornar as imagens clicáveis
          // Apenas exiba o ícone junto com o nome do arquivo
  
          return (
            <div key={index} className="MessageFileItem">
              <FontAwesomeIcon icon={fileIcon} className="MessageFileIcon" />
              <span className="MessageFileName">{fileName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  const getFileNameFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
      return decodeURIComponent(fileName);
    } catch (error) {
      console.error("Invalid URL:", url);
      return url; // Retorna a URL inteira se não for válida
    }
  };

  const getFileExtension = (url) => {
    try {
      const urlObj = new URL(url);
      const pathname = urlObj.pathname;
      return pathname.split('.').pop();
    } catch (error) {
      console.error("Invalid URL:", url);
      return ""; // Retorna string vazia se não for válida
    }
  };

  const renderSpecialtyTag = () => {
    if (!specialty) return null;

    const tooltipId = `tooltip-specialty-${idChat}-${timestamp}`;

    return (
      <>
        <span data-tooltip-id={tooltipId}>
          <Tag
            className="flex flex-row items-center gap-1 cursor-pointer"
            icon={
              <FontAwesomeIcon icon={faRedhat} className="text-gray-600" />
            }
          >
            {specialty?.name}
          </Tag>
        </span>
        <Tooltip id={tooltipId} place="top" effect="solid" />
      </>
    );
  };

  const renderBrandVoiceTag = () => {
    if (!brandVoice) return null;

    const tooltipId = `tooltip-brandvoice-${idChat}-${timestamp}`;

    return (
      <>
        <span data-tooltip-id={tooltipId}>
          <Tag
            className="flex flex-row items-center gap-1 cursor-pointer"
            icon={
              <FontAwesomeIcon icon={faBullhorn} className="text-gray-600" />
            }
          >
            {brandVoice?.name}
          </Tag>
        </span>
        <Tooltip id={tooltipId} place="top" effect="solid" />
      </>
    );
  };

  const typeText = (text) => {
    onStartTyping();
    let i = 0;
    let accumulatedText = "";
    if (typeTimer.current) {
      clearInterval(typeTimer.current); // Limpar intervalo anterior, se existir
    }
    typeTimer.current = setInterval(() => {
      if (i >= text.length) {
        clearInterval(typeTimer.current);
        setTypedMessage(text);
        onFinishTyping();
        return;
      }

      accumulatedText += text.charAt(i);
      if (i % 10 === 0 || i === text.length - 1) {
        setTypedMessage((prevTypedMessage) => prevTypedMessage + accumulatedText);
        accumulatedText = "";
        scrollDown();
      }
      i++;
    }, 15);
  };

  const getMessagePositionClass = () => {
    return role === "SYSTEM" ? "left" : "right";
  };

  const getUserProfilePictureUrl = () => {
    if (user.picture) {
      if (user.picture.startsWith("http")) {
        return user.picture;
      }
      return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/${user.idUser}/256/${user.picture}`;
    }
    return `${process.env.REACT_APP_PROFILE_PICTURE_S3_BUCKET_PREFIX_URL}/default/256/default.png`;
  };

  const renderMessageAuthor = () => {
    if (role === "SYSTEM") {
      return (
        <div className="flex items-center gap-2">
          <Avatar
            size={48}
            className={`MessageIcon ${getMessagePositionClass()}`}
            src={Logo}
          />
          <div className="flex flex-col gap-1">
            <div>
              <label className="text-[16px] font-bold">CMOs.ai</label>
            </div>
            <div className="flex flex-row items-center">
              {renderSpecialtyTag()}
              {renderBrandVoiceTag()}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex justify-end items-center gap-4">
          <div>
            <label className="text-[16px] font-bold">
              {user.name.split(" ")[0]}
            </label>
          </div>
          <Avatar size={48} src={getUserProfilePictureUrl()} />
        </div>
      );
    }
  };

  const renderIconTray = () => {
    if (role === "SYSTEM" && codeSnippet) {
      return (
        <div className="float-right text-white mb-2">
          {renderCopyButton()}
          {renderPreviewButton()}
        </div>
      );
    }
    return null;
  };

  const renderCopyButton = () => {
    const tooltipId = `tooltip-copy-${idChat}-${timestamp}`;

    return (
      <>
        <span data-tooltip-id={tooltipId}>
          <div
            className={
              "transition-all duration-100 ease-in cursor-pointer hover:text-white hover:scale-125"
            }
            onClick={handleCopy}
          >
            <FontAwesomeIcon icon={faClone} />
          </div>
        </span>
        <Tooltip id={tooltipId} place="top" effect="solid" />
      </>
    );
  };

  const renderPreviewButton = () => {
    return (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          console.log(
            "Preview button clicked in Message component:",
            codeSnippet
          );
          if (onArtifactPreview && codeSnippet) {
            onArtifactPreview(codeSnippet);
          }
        }}
        style={{ marginTop: "8px", marginLeft: "8px" }}
      >
        Preview
      </Button>
    );
  };

  function detectCodeSnippet(text) {
    const codeRegex = /```(\w*)\n([\s\S]*?)```/g;
    let match;
    let snippets = [];

    while ((match = codeRegex.exec(text)) !== null) {
      const [fullMatch, language = "", codeContent = ""] = match;

      const lang = language.toLowerCase().trim();
      const code = codeContent.trim();

      // Definir padrões que indicam comandos de shell ou outras linguagens para ignorar
      const shellCommandPatterns = [
        /^(\$|#)\s+/,
        /^npx\s+/,
        /^npm\s+/,
        /^yarn\s+/,
        /^cd\s+/,
        /^mkdir\s+/,
        /^rm\s+/,
        /^echo\s+/,
        /^cat\s+/,
        /^ls\s+/,
        /^pwd\s+/,
      ];

      // Verificar se o conteúdo contém comandos de shell
      const isShellCommand = shellCommandPatterns.some((pattern) =>
        pattern.test(code)
      );

      if (isShellCommand || ["shell", "bash"].includes(lang)) {
        console.log("Skipping shell command code block");
        continue; // Pular este bloco de código
      }

      // Prosseguir se o código parecer válido
      snippets.push({
        language: lang || "javascript",
        content: code,
      });
    }

    if (snippets.length > 0) {
      // Retornar o primeiro snippet encontrado. Ajuste se precisar de múltiplos snippets.
      return snippets[0];
    }

    return null;
  }

  if (!content || content.trim() === "") {
    return null;
  }

  const roleCustomClass =
    role === "SYSTEM"
      ? "px-4 my-2 bg-white rounded-[12px] MessageSystem text-white"
      : "px-4 my-2 bg-white rounded-[12px] MessageUser";

  return (
    <div>
      <div key={timestamp}>
        {renderMessageAuthor()}
        <div
          className={`${getMessagePositionClass()} ${
            isMobile ? "max-w-[95%]" : "max-w-[70%]"
          } ${roleCustomClass}`}
        >
          {renderFiles()} {/* Renderizar arquivos aqui */}
          {messageContent}
          {renderIconTray()}
        </div>
        <div style={{ clear: "both" }}></div>
      </div>
    </div>
  );
};

Message.propTypes = {
  idChat: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  timestamp: PropTypes.instanceOf(Date).isRequired,
  onboard: PropTypes.bool,
  user: PropTypes.object.isRequired,
  picture: PropTypes.string,
  specialty: PropTypes.object,
  brandVoice: PropTypes.object,
  files: PropTypes.arrayOf(PropTypes.string), // Adicionado o propType 'files'
  isMobile: PropTypes.bool,
  onArtifactPreview: PropTypes.func.isRequired,
  onStartTyping: PropTypes.func,
  onFinishTyping: PropTypes.func,
  scrollDown: PropTypes.func,
};

export default React.memo(Message);
