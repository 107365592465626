import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import './Step1.css';

const Step1 = ({ onNext, onSkip }) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        console.log('Executando useEffect no Step1');
        const timeoutId = setTimeout(() => {
            const labelElement = document.querySelector('.chat-menu-item');
            console.log('labelElement:', labelElement);
            if (labelElement) {
                const menuItemElement = labelElement.closest('.ant-menu-item');
                console.log('menuItemElement:', menuItemElement);
                if (menuItemElement) {
                    const rect = menuItemElement.getBoundingClientRect();
                    console.log('Rect:', rect);

                    // Ajuste na posição vertical
                    const offset = 10; // Ajuste a distância acima do botão
                    setPosition({
                        top: rect.top + window.scrollY - offset - 30, // Subtrai para posicionar acima
                        left: rect.right + window.scrollX + 10, // Mantém a posição horizontal
                    });

                    console.log('Posição do balão definida:', {
                        top: rect.top + window.scrollY - offset - 30,
                        left: rect.right + window.scrollX + 10,
                    });
                }
            }
        }, 0);

        return () => clearTimeout(timeoutId);
    }, []);

    const handleNext = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            onNext();
        }, 300); // Duração da animação em milissegundos
    }, [onNext]);

    const handleSkip = useCallback(() => {
        setIsClosing(true);
        setTimeout(() => {
            onSkip();
        }, 300); // Duração da animação em milissegundos
    }, [onSkip]);

    return (
        <div
            className={`onboarding-step1 ${isClosing ? 'closing' : ''}`}
            style={{
                top: position.top,
                left: position.left,
            }}
        >
            <div className="balloon">
                <span className="step-number">1</span>
                <p>Aqui você terá acesso direto às funcionalidades da plataforma.</p>
                <div className="actions">
                    <button onClick={handleNext} className="next-button">Próximo</button>
                </div>
            </div>
        </div>
    );
};

Step1.propTypes = {
    onNext: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
};

export default Step1;

