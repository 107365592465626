// src/components/onboarding/agents/Step3.jsx

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Step3.css';

const AgentsStep3 = ({ onNext }) => {
  const [position, setPosition] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // Novo estado para controle de visibilidade

  useEffect(() => {
    const adjustPosition = () => {
      // Seleciona o primeiro agente que aparece ao clicar em "Agentes"
      const agentCard = document.querySelector('.ToolCard');
      if (agentCard) {
        const rect = agentCard.getBoundingClientRect();
        const balloon = document.querySelector('.agents-onboarding-step3 .agents-step3-balloon');
        const balloonWidth = balloon ? balloon.offsetWidth : 280; // Usando max-width definido no CSS

        const viewportWidth = window.innerWidth;

        // Posiciona o balão à direita do agente
        let top = rect.top + window.scrollY + rect.height / 2 - 100; // Ajuste vertical
        let left = rect.right + window.scrollX + 20; // Ajuste horizontal para posicionar à direita

        // Verifica se o balão ultrapassa a largura da tela
        if (left + balloonWidth > viewportWidth - 20) {
          // Posiciona o balão à esquerda do agente
          left = rect.left + window.scrollX - balloonWidth - 20;
        }

        setPosition({ top, left });
      } else {
        // Se o elemento não foi encontrado, tenta novamente após um pequeno atraso
        setTimeout(adjustPosition, 100);
      }
    };

    // Chama a função imediatamente
    adjustPosition();

    // Atualiza a posição em caso de redimensionamento da janela
    window.addEventListener('resize', adjustPosition);

    // Adiciona a classe 'show' para iniciar a animação de entrada
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Pequeno atraso para garantir a transição

    // Limpeza
    return () => {
      window.removeEventListener('resize', adjustPosition);
    };
  }, []);

  // Função para lidar com o clique no botão "Próximo"
  const handleNext = () => {
    setIsVisible(false); // Inicia a animação de saída
    setTimeout(() => {
      onNext(); // Chama a função onNext após a animação
    }, 500); // Tempo deve corresponder à duração da transição (0.5s)
  };

  // Se a posição ainda não foi calculada, não renderiza o balão
  if (!position) {
    return null;
  }

  return (
    <div
      className={`agents-onboarding-step3 ${isVisible ? 'show' : ''}`}
      style={{
        top: position.top,
        left: position.left,
      }}
    >
      <div className="agents-step3-balloon">
        <span className="step-number">3</span>
        <p>
          Ao selecionar um agente especialista, siga as instruções para tornar suas tarefas mais eficientes.
        </p>
        <div className="actions">
          <button onClick={handleNext} className="next-button">Finalizar</button>
        </div>
      </div>
    </div>
  );
};

AgentsStep3.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default AgentsStep3;
