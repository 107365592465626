import React from 'react';
import { Statistic } from "antd";
import { Coins } from "lucide-react";
import CountUp from "react-countup";
import useUser from "../../utils/hooks/useUser";
import moment from "moment/moment";
import { useWallet } from "../../utils/hooks/useWallet";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const CreditsCounter = () => {
    const { user, isLoading } = useUser();
    const { textCredits, nextRecharge } = useWallet();

    const formatter = (value) => {
        if (value === -1) {
            return 'Ilimitado';
        } else {
            return <CountUp end={value} />;
        }
    };

    const renderCredits = () => {
        return (
            <Tippy
                content={`Seus créditos serão recarregados em ${moment(nextRecharge).format('DD/MM/YYYY')}`}
                placement="left"
            >
                <div className="credits-counter">
                    <Statistic
                        title="Créditos restantes"
                        value={textCredits}
                        prefix={<Coins size={18} />}
                        loading={!user || isLoading}
                        formatter={formatter}
                    />
                </div>
            </Tippy>
        );
    };

    return renderCredits();
};

export default CreditsCounter;
